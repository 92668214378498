import styled from 'styled-components';
import { PrimaryButton } from 'components';

const Button = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})`
  min-width: 15rem;
`;

export default Button;
